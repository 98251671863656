$black: #000000;
$black-1: #1a181e;
$black-2: #1e2640;
$black-3: #4d4d4d;
$black-4: #101d2f;
$black-5: #1b181e;

$white: #ffffff;
$white-1: #f7f7f7;
$white-2: #d9d9d9;
$white-3: #ededed;
$white-4: #f5f5f5;
$white-5: #d8d8d8;
$white-6: #f2f2f2;
$white-7: #e8e8e8;
$white-8: #e5e5e5;
$white-9: #e6e6e6;
$white-10: #fafafa;
$white-11: #d3ddd4;
$white-12: #e9e9e9;
$white-13: #f8f8f8;

$gray-1: #808080;
$gray-2: #989898;
$gray-3: #cccccc;
$gray-4: #999999;
$gray-5: #666666;
$gray-6: #b2b2b2;
$gray-7: #fab73b1a;
$gray-8: #b3b3b3;
$gray-9: #e7e7e74d;
$gray-10: #fff9db;

$divider-color: #ebeef1;

$purple-1: #146eb4;
$purple-2: #115f9c;
$purple-3: #13458c;
$purple-hover: #0e4f82;
$purple-plan: #810576;

$red-1: #e50b20;
$red-2: #cc0a1d;
$red-3: #df1e5b;
$red-4: #d00081;
$red-5: #c13584;
$red-6: #e50b201a;
$red-7: #383637;
$red-8: #440000;
$red-9: #c5334e;
$red-10: #d71d30;

$green-1: #17b31b;
$green-2: #139116;
$green-3: #00af87;
$green-4: #045e54;
$green-5: #e3ffc7;
$green-6: #052e21;

$orange-1: #ee741f;
$orange-2: #cc631b;
$orange-3: #ea6f57;
$orange-4: #fab73b;
$orange-5: #fb7948;
$orange-6: #994b14;
$orange-7: #f6bf50;
$orange-8: #f7e6dc;
$orange-9: #fde1da;
$orange-10: #fffbf5;

$violet-1: #7d00be;
$violet-2: #6457a6;

$magenta-1: #29202a;

$brown-1: #52362a;
$brown-2: #452d23;
$brown-3: #4b1600;
$brown-4: #78380c;

$body-color: $black-1;

$google-primary: #dd4b39;
$google-hover: #cf4635;

$facebook-primary: #1877f2;
$facebook-secondary: #0169e1;
$facebook-hover: #166dde;

$focus-blue-box-shadow: 0px 0px 0px 3px rgba(20, 110, 180, 0.2);
$focus-red-box-shadow: 0px 0px 0px 3px rgba(229, 11, 32, 0.2);

$sm-breakpoint: (
  min-width: 576px,
);
$md-breakpoint: (
  min-width: 768px,
);
$lg-breakpoint: (
  min-width: 992px,
);
$xl-breakpoint: (
  min-width: 1200px,
);

$sm-container-width: 540px;
$md-container-width: 720px;
$lg-container-width: 960px;
$xl-container-width: 1140px;

$sidebar-width: 224px;
$toolbar-height: 64px;
$notificationbar-height: 42px;

$fixed-container-width: 1200px;
$fixed-container-width-sm: 760px;

$sm-breakpoint: '(min-width: 576px)';
$md-breakpoint: '(min-width: 768px)';
$lg-breakpoint: '(min-width: 992px)';
$xl-breakpoint: '(min-width: 1200px)';
$xxl-breakpoint: '(min-width: 1400px)';
$xxxl-breakpoint: '(min-width: 1600px)';
