$space: 64;
$font-size: 40;
$font-weight: 900;

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ws-normal {
  white-space: normal !important;
}
.ws-nowrap {
  white-space: nowrap;
}
// PADDING & MARGINS 0, 2, 4 ... 50px
@while $space >= 0 {
  @include spacing($space);
  $space: $space - 2;
}

.m-auto {
  margin: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}
.mr-auto,
.mx-auto {
  margin-right: auto;
}
.mt-auto,
.my-auto {
  margin-top: auto;
}
.mb-auto,
.my-auto {
  margin-bottom: auto;
}
.mb-20 {
  margin-bottom: -20px;
}
.mb-24 {
  margin-bottom: -24px;
}
.mb96 {
  margin-bottom: 96px;
}

.stock-scarcity > .form-group {
  margin-bottom: 8px !important;
}

.pb40-i {
  padding-bottom: 40px !important;
}

.no-border {
  border-radius: 0;
}

.no-margin {
  margin: 0;
}

.mb0-imp {
  margin-bottom: 0 !important;
}

.mt0-i {
  margin-top: 0 !important;
}

.warehouse-layout-margin {
  margin: 32px auto;
}

// FONT SIZE 12, 14, 16 ... 40px
@while $font-size >= 12 {
  .f#{$font-size} {
    font-size: #{$font-size}px;
  }
  $font-size: $font-size - 2;
}

// FONT Weight 100, 200, 300 ... 900
@while $font-weight >= 100 {
  .fw#{$font-weight} {
    font-weight: #{$font-weight};
  }
  $font-weight: $font-weight - 100;
}

.section-text-1 {
  @include font-size-weight(28, 36, 500);
}
.section-text-2 {
  @include font-size-weight(24, 36, 500);
}
.section-text-3 {
  @include font-size-weight(20, 28, 500);
}
.section-text-4 {
  @include font-size-weight(18, 26, 500);
}
.section-text-5 {
  @include font-size-weight(16, 24, 500);
}
.section-text-6 {
  @include font-size-weight(15, 22, 500);
}
.section-text-7 {
  @include font-size-weight(14, 20, 500);
}
.section-text-8 {
  @include font-size-weight(14, 18, 500);
}
.section-text-9 {
  @include font-size-weight(20, 32, 500);
}
.section-text-10 {
  @include font-size-weight(36, 42, 500);
}
.section-text-11 {
  @include font-size-weight(24, 32, 500);
}
.section-text-12 {
  @include font-size-weight(32, 38, 500);
}
.section-text-13 {
  @include font-size-weight(12, 16, 500);
}
.section-text-14 {
  @include font-size-weight(14, 20, 400);
}
.section-text-15 {
  @include font-size-weight(12, 16, 600);
}
.section-text-16 {
  @include font-size-weight(40, 42, 500);
}
.section-text-17 {
  @include font-size-weight(16, 22, 500);
}
.section-text-18 {
  @include font-size-weight(32, 40, 500);
}
.section-text-19 {
  @include font-size-weight(15, 20, 500);
}
.section-text-20 {
  @include font-size-weight(13, 16, 500);
}
.section-text-21 {
  @include font-size-weight(40, 48, 500);
}
.bold-text-1 {
  @include font-size-weight(20, 24, 600);
}
.bold-text-2 {
  @include font-size-weight(32, 40, 600);
}
.bold-text-14 {
  @include font-size-weight(14, 22, 700);
}
.bold-text-48 {
  @include font-size-weight(48, 56, 600);
}

.text-0 {
  @include font-size(28, 36);
}
.text-1 {
  @include font-size(18, 26);
}
.text-2 {
  @include font-size(16, 24);
}
.text-2a {
  @include font-size(16, 22);
}
.text-3 {
  @include font-size(14, 22);
}
.text-4 {
  @include font-size(13, 16);
}
.text-5 {
  @include font-size(12, 18);
}
.text-6 {
  @include font-size(13, 20);
}
.text-7 {
  @include font-size(12, 20);
}
.text-8 {
  @include font-size(14, 20);
}
.text-9 {
  @include font-size(13, 18);
}
.text-10 {
  @include font-size(12, 16);
}
.text-11 {
  @include font-size(8, 9);
}
.text-12 {
  @include font-size(11, 14);
}
.text-13 {
  @include font-size(24, 32);
}
.text-14 {
  @include font-size(14, 18);
}
.text-15 {
  @include font-size(15, 20);
}
.text-16 {
  @include font-size(15, 22);
}
.text-17 {
  @include font-size(10, 12);
}

.text-dining {
  color: $red-3;
}
.text-white {
  color: white;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-ucase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-medium {
  font-weight: 500;
}
.text-medium-imp {
  font-weight: 500 !important;
}
.text-semibold {
  font-weight: 600;
}
.text-normal {
  font-weight: 400;
}
.text-italic {
  font-style: italic;
}

.text-info {
  @extend .text-3;
  color: $gray-1;
}

.text-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.full-vh {
  height: 100vh;
}
.full-h {
  height: 100%;
}
.full-vw {
  width: 100vw;
}
.full-w {
  width: 100%;
}
.half-w {
  width: 50%;
}
.full-w-i {
  width: 100% !important;
}
.wv-center {
  min-height: 100%;
  @include display-flex;
}

.min-w-fit {
  min-width: fit-content;
}

.c-white {
  color: $white;
}
.c-white-6 {
  color: $white-6;
}
.c-red-1 {
  color: $red-1;
}
.c-red-3 {
  color: $red-3;
}
.c-red-10 {
  color: $red-10;
}
.c-white-i {
  color: $white !important;
}
.c-white-9 {
  color: $white-9;
}
.c-gray-1 {
  color: $gray-1;
}
.c-gray-3 {
  color: $gray-3;
}
.c-gray-4 {
  color: $gray-4;
}
.c-gray-5 {
  color: $gray-5;
}
.c-gray-6 {
  color: $gray-6;
}
.c-purple-1 {
  color: $purple-1;
}
.c-purple-1-i {
  color: $purple-1 !important;
}
.c-purple-4 {
  color: $purple-3;
}
.c-green-1 {
  color: $green-1;
}
.c-black-3 {
  color: $black-3;
}
.c-black-3-i {
  color: $black-3 !important;
}
.c-black {
  color: $black;
}
.c-black-1 {
  color: $black-1;
}
.c-orange-1 {
  color: $orange-1;
}
.c-white-2{
  color: $white-2;
}

.c-h-purple-2-i:hover {
  color: $purple-2 !important;
}
.c-h-purple-1:hover {
  color: $purple-1;
}

.c-violet-2 {
  color: $violet-2;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clearfix {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

.d-none,
.hidden {
  display: none;
}
.v-hidden {
  visibility: hidden;
}
.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-flex-c-s {
  @include display-flex(center, space-between);
}
.d-flex-start-s {
  @include display-flex(flex-start, space-between);
}
.d-flex-c-start {
  @include display-flex(center, flex-start);
}
.d-flex-c-end {
  @include display-flex(center, flex-end);
}
.d-flex-fs-stretch {
  @include display-flex(stretch, flex-start);
}
.d-flex-c-c {
  @include display-flex;
}
.row-wrap {
  flex-flow: row wrap;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.pos-rel {
  position: relative;
}
.pos-fixed {
  position: fixed !important;
}
.pos-center {
  left: 50%;
}
.pos-abs {
  position: absolute;
}

.cur-p {
  cursor: pointer;
  user-select: none;
}
.cur-disabled {
  cursor: not-allowed;
  user-select: none;
}
.cur-grab {
  cursor: grab;
  user-select: none;
}
.cur-grabbing {
  cursor: grabbing;
  user-select: none;
}
.cur-auto {
  cursor: auto;
  user-select: none;
}
.cur-default {
  cursor: default;
  user-select: none;
}

.user-select-text {
  user-select: text;
}

.underline {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none !important;
}

.underline-h:hover {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.anchor-1 {
  @include anchor-link($purple-1, $purple-2);
  .content-grp {
    @include anchor-link($purple-1, $purple-2);
  }

  &.disabled {
    opacity: 0.4;
  }
}
.anchor-2 {
  @include anchor-link($gray-1, $black-3);
}
.anchor-3 {
  @include anchor-link($red-4, $red-3);
}
.anchor-4 {
  @include anchor-link($orange-1, $orange-2);
}

.anchor-5 {
  @include anchor-link($red-1, $red-2);
}

.anchor-6 {
  @include anchor-link($black-1, $black-3);
}

.anchor-7 {
  @include anchor-link($black-3, $gray-1);
}

.break-word {
  word-break: break-all;
}

.word-break {
  word-break: break-word;
}

.external-link {
  color: $body-color;
  &:hover {
    text-decoration: underline;
  }
  &.external-link-disabled {
    opacity: 0.4;
  }
}

.arial-font {
  font-family: Arial;
}

.bg-white-1 {
  background-color: $white-1 !important;
}

.bg-green-1 {
  background-color: $green-1 !important;
}

.line-height-16 {
  line-height: 16px !important;
}
