.products-not-found {
  @include display-flex;
  flex-direction: column;
  &.inventory {
    height: calc(100vh - 256px);
  }
  &:not(.products, .inventory) {
    height: calc(100vh - 308px);
    max-width: 480;
  }
  img {
    @include content-size(324, 192);
  }
}

.quick-navigation-card {
  @extend .card;
  padding: 24px;
  padding-bottom: 12px;
  border-radius: 4px;
  min-width: 270px;
  height: fit-content;
  position: sticky;
  top: 32px;

  .quick-navigation-button-container {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .quick-navigation-button-container:not(:last-child) {
    border-bottom: solid 1px $white-8;
  }
}

.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 24px;
  .product-item {
    padding: 16px;
    position: relative;
    transition: box-shadow 0.2s;
    box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.04);
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(26, 24, 30, 0.1);
    }

    .action-menu-icon {
      position: absolute;
      top: 8px;
      right: 6px;
    }
    .card {
      color: initial;

      .product-item-container {
        @include display-flex(center, flex-start);
        img {
          @include content-size(96, 96);
          border-radius: 6px;
          margin-right: 16px;
          object-fit: contain;
          border: 1px solid $white-9;
        }
        .prod-name {
          max-width: 210px;
          text-decoration: none;
          color: $black-1;
          cursor: pointer;
          display: inline-block;
          &:hover {
            color: $black-3;
          }
        }
        .product-cost {
          @include font-size-weight(16, 24, 500);
          margin-bottom: 3px;
          .original-cost {
            margin-left: 4px;
            text-decoration: line-through;
            @include font-size-weight(14, 20, 400);
            color: $gray-1;
          }
        }
        .reorder-button {
          padding: 0;
          border: 0;
          background-color: transparent;
          cursor: grab;
        }
        .reorder-button svg {
          display: block;
        }
      }
    }
    .share-product-link {
      cursor: pointer;
      @extend .d-flex-c-c;
      border-top: 1px solid $white-2;
      margin-top: 12px;
      padding-top: 12px;
      .share-link-icon {
        margin: -4px 8px 0 0;
      }
    }
  }
}

.grab-pointer {
  cursor: grab;
}

.shopify-importer-card,
.advance-catalogue-card {
  border: 1px solid $white-2;
  box-shadow: none;
  border-radius: 4px;
  .close-btn {
    cursor: pointer;
  }
}

.vendor-multi-select {
  width: 100% !important;
  .input-wrap {
    width: 100% !important;
    .form-fake-input {
      font-size: 16px;
      &.placeholder {
        font-size: 16px;
      }
    }
  }
}

.product-information-section {
  margin-bottom: 32px;
  padding: 24px 24px 2px 24px;
  .form-group:not(.textarea-wrap) {
    margin-bottom: 24px;
  }
  .form-group.textarea-wrap {
    margin-bottom: 0px;
  }
  .form-quantity-input-group {
    .form-quantity-input-wrap {
      border: 1px solid $white-2;
      border-radius: 4px;
      display: flex;
      &.focused {
        border-color: $purple-1;
        box-shadow: $focus-blue-box-shadow;
      }
      &.has-error {
        border-color: $red-1;
        box-shadow: $focus-red-box-shadow;
      }
      input {
        border: none;
        box-shadow: none;
        min-width: 50%;
      }
      .unit-input {
        min-width: 50%;
        padding: 11px 0;
        &:focus {
          outline: none;
        }
        &.disabled {
          background-color: $white-10;
        }
        .unit-label {
          @include font-size-weight(16, 24, 400);
          padding-left: 16px;
          cursor: pointer;
        }
        .custom-select__control,
        .unit-label {
          min-height: 24px !important;
          height: 24px;
          border: none !important;
          border-left: 1px solid $white-2 !important;
          border-radius: 0 !important;
          .custom-select__value-container {
            overflow: initial;
            > div {
              &:last-child {
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0;
                padding-top: 0;
              }
            }
          }
          .custom-select__single-value {
            position: relative;
            transform: none;
            top: 0;
            color: $black-1;
          }
        }
      }
    }
  }
  .food-category-select-wrap {
    .custom-select__value-container--has-value {
      padding: 2px 0 !important;
    }
    .custom-select__option {
      &:active {
        background-color: transparent;
      }
    }
    .custom-select__value-container {
      overflow: initial;
      > div {
        &:last-child {
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 0;
          padding-top: 0;
          input {
            caret-color: $white;
          }
        }
      }
    }
  }
  .per-qty-text-section {
    margin-top: 28px;
    display: flex;
    align-items: center;
    height: 48px;
    > span {
      @include font-size-weight(14, 20, 500);
      color: $black-3;
    }
  }
  .price-input-row {
    padding-bottom: 16px;
    .discount-text-wrap {
      @include font-size-weight(14, 20, 500);
      color: $black-3;
      .original-cost {
        font-weight: 400;
        text-decoration: line-through;
        color: $gray-4;
        margin-left: 4px;
      }
    }
    .product-discount-badge {
      @include font-size-weight(12, 16, 600);
      padding: 2px 8px;
      border-radius: 2px;
      color: $white;
      background-color: $orange-1;
    }
    .form-group {
      margin-bottom: 8px;
    }
  }
  .row {
    margin: 0 -12px;
    .col-md-6,
    .col-xs-12 {
      padding: 0 12px;
    }
  }
  .category-select-wrap {
    .custom-select__menu-list > div.custom-select__option:first-child {
      @include font-size-weight(16, 20, 500);
      padding: 16px;
    }
  }
  .category-wrapper {
    position: relative;
    .category-text {
      @include font-size-weight(16, 20, 400);
      position: absolute;
      margin-left: 16px;
      margin-right: 16px;
      top: 42px;
      width: -webkit-fill-available;
      background-color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .category-name {
      &--with-subcategories {
        color: $gray-1;
      }
    }
  }
}

.attribute-chip-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .attribute-row {
    display: flex;
    column-gap: 8px;
    row-gap: 12px;
    align-items: center;
    flex-wrap: wrap;
  }
}

.variant-form {
  // max-width: 100%;
  margin: 32px 0 0;
  .seo-link {
    &.arrow-up {
      width: 5px;
      height: 6.5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #70757a;
      margin-left: 5px;
      display: inline-flex;
    }
  }
  .seo-main-text {
    font-size: 20px;
    line-height: 24px;
    color: #1a0dab;
    width: 600px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .seo-desc {
    color: #4d5156;
    word-break: break-word;
    text-overflow: ellipsis;
    width: 600px;
  }

  .permalink-input {
    margin-bottom: 0 !important;

    .input-group-wrap {
      display: none;
    }
  }

  .edit-page-link-field {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 32px 0 0;
  }
  .variant-form-row {
    & > div {
      margin-bottom: 16px;
    }
    .seo-tag {
      margin-bottom: 24px;

      .spinner-xs {
        margin-left: 2px;
        width: 10px;
        display: inline;

        & > div {
          width: 3px;
          height: 3px;
          background-color: $black-3;
          border-radius: 100%;
          display: inline-block;
          -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        }
      }

      .spinner-xs .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
        margin-right: 2px;
      }
      .spinner-xs .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
        margin-right: 2px;
      }
    }
    .add-on-item {
      position: relative;
      .add-on-price {
        position: absolute;
        right: 26px;
        bottom: 40px;
      }
    }
    .form-group {
      margin-bottom: 8px;
    }
    .variant-row-label {
      @include font-size(14, 20);
      text-align: right;
      color: $black-3;
      .remove-variant {
        color: $red-1;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.variants-form-section {
  padding: 24px;
  .variants-options-container {
    border-top: 1px solid $white-2;
    margin-top: 12px;
    padding-top: 20px;
    .variants-option-row {
      &:not(:first-child) {
        margin-top: 20px;
      }
      .variant-row-label {
        @include font-size(14, 20);
        text-align: right;
        color: $black-3;
        margin-bottom: 8px;
        .remove-variant {
          color: $red-1;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .row {
        .col-md-3 {
          padding-right: 4px;
        }
        .col-md-9 {
          padding-left: 20px;
        }
      }
    }
  }
}

.variants-table-wrapper {
  margin-right: -24px;
  overflow-y: scroll;
  & .table-data-row {
    border-bottom: 0 !important;
    padding-bottom: 0;
  }

  & .meta {
    width: 345px;

    .variants-table-meta-block {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      .choose-variant-image-trigger {
        height: 64px;
        cursor: pointer;

        &.disabled {
          opacity: 0.4;
        }

        .uploaded-image img {
          border-radius: 4px;
        }
        .uploaded-image {
          .uploaded-img-overlay {
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 4px !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      & .icon-wrapper {
        width: 64px;
        height: 64px;
        border-radius: 4px;
        border: solid 1px $gray-3;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      & .texts {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
        word-break: break-word;

        & .label {
          @extend .text-2;
        }
      }
    }

    .table-header {
      width: 100%;
      margin-left: 64px;
    }

    & .table-data-container {
      &.scrolled {
        border-right: solid 1px $white-9;
      }
    }

    & > .table-header-wrapper {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  & .data {
    width: calc(100% - 345px);
    overflow: auto;

    & > .table-header-wrapper {
      width: max-content;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      justify-content: flex-start;
    }

    & .table-header-wrapper,
    .table-data-row {
      align-items: center;

      & > * {
        margin-right: 24px;
        justify-content: start;

        .form-group input {
          line-height: normal;
        }

        .input-group-wrap {
          height: 40px;
          input {
            line-height: normal;
            height: 40px;
          }
        }
        textarea {
          height: 40px;
          line-height: normal;
        }
      }

      & > .o-price {
        min-width: 125px;
        max-width: 125px;
      }
      & > .d-price {
        min-width: 125px;
        max-width: 125px;
      }
      & > .sku-id {
        min-width: 175px;
        max-width: 175px;
        .input-wrap {
          width: 100%;
        }
        input {
          padding-left: 12px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 12px;
        }
      }
      & > .inv-q {
        min-width: 240px;
        max-width: 240px;
        .input-wrap {
          width: 100%;
        }
        .form-fake-input {
          font-size: 16px;
          height: 40px;
          padding: 0 12px;
          display: flex;
          align-items: center;
          width: 220px;
        }
      }
      & > .sdd-status {
        min-width: 50px;
      }
      & > .wgt {
        min-width: 150px;
        max-width: 150px;
        .input-wrap {
          width: 100%;
        }
      }
      & > .gtin {
        min-width: 150px;
        max-width: 150px;
        .input-wrap {
          width: 100%;
        }
      }
      & > .google-category {
        min-width: 250px;
        max-width: 250px;
        .input-wrap {
          width: 100%;
        }
      }
      & > .vendor {
        min-width: 250px;
        max-width: 250px;
        .vendor-multi-select {
          .form-fake-input {
            padding: 6px 12px !important;
          }
        }
        .custom-select__control {
          height: 40px;
          min-height: 40px !important;
        }
      }
      & > .commission-model {
        min-width: 250px;
        max-width: 250px;
        > :first-child {
          width: 100%;
        }
        .custom-select__control {
          height: 40px;
          min-height: 40px !important;
        }
      }
      & > .best-before {
        min-width: 150px;
        max-width: 150px;
      }
      & .actions {
        min-width: 25px;
        max-width: 25px;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 0px;
      }
    }

    & .table-data-row {
      & > * {
        @extend .d-flex-c-c;

        height: 64px;
      }

      & .form-group {
        margin: 0;
      }
    }
  }
}

.variants-table-reorder-wrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;

  .meta {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .reorder-button {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    cursor: pointer;
  }
}

.attributes-panel-header {
  @extend .d-flex, .justify-center, .align-center;
  width: fit-content;
}

.attribute-form {
  margin: 32px 0 0;
  &.MuiAccordion-root.Mui-expanded {
    margin: 32px 0 0;
  }
  .divider:nth-child(1) {
    margin-bottom: 23px;
    margin-top: -20px;
  }

  .spinner-btn.btn-outline-gray-1 {
    max-height: 36px;
  }
}

.modify-attributes-form-wrap {
  width: 760px;
  margin: 32px auto;
  .attribute-row {
    @extend .mb24;
    .attribute-input-container {
      @extend .d-flex, .gap24;
      .form-group {
        margin-bottom: 0px !important;
      }
    }
    .delete-icon {
      @extend .d-flex, .cur-p;
      height: auto;
      align-items: center;
      margin-top: 26px;
      svg {
        path {
          fill: $black-3;
        }
      }
    }
  }
}

.attributes-plugin-container {
  width: 760px;
  margin: 32px auto;
  .active-badge {
    color: $white;
    background: $green-1;
    padding: 2px 6px;
    border-radius: 4px;
  }
  .access-here-image-container {
    position: absolute;
    top: 94px;
    left: 468px;
  }
}

.attribute-reorder-container {
  @extend .d-flex, .row;
  width: 760px;
  margin: 32px auto;
  .card {
    width: 852px;
  }
}

.product-images-variant {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  overflow: scroll;
  margin-right: -24px;

  .uploaded-image {
    min-width: 108px;
  }
}

.product-inventory-input-wrapper {
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: stretch;
  gap: 24px;
  .form-group {
    margin-bottom: 8px;
  }
}

.variants-inventory-update-table {
  .table-header-wrapper,
  .table-data-row {
    justify-content: start;
    .inventory-update {
      height: inherit;
    }

    & .texts {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      word-break: break-word;
      & .label {
        @extend .text-2;
      }
    }
    & .product {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      height: max-content;
      gap: 16px;
      word-break: break-word;
      width: 98%;
      font-weight: 500;
      img {
        @include content-size(48, 48);
        border-radius: 4px;
        object-fit: contain;
        border: solid 1px $white-9;
      }
    }

    & .unit > * {
      height: 48px;
      display: flex;
      align-items: center;
      // min-width: 45%;
    }
    & .unit {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      // min-width: 45%;
      width: 46%;
      row-gap: 19px;
    }
    .input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      width: 26%;
      // min-width: 30%;
    }
  }
}
.table-header-wrapper,
.table-data-row {
  .form-group {
    width: 112px;
    height: 40px;
  }

  & .texts {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    word-break: break-word;
    & .label {
      @extend .text-2;
    }
  }
  & .unit > * {
    height: 48px;
    display: flex;
    align-items: center;
    min-width: 30%;
  }
  & .unit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    // min-width: 45%;
    width: 14%;
  }
  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    // min-width: 45%;
    width: 16%;
  }
}

.create-category-modal-body {
  margin-top: 24px;
  .registration-select {
    margin-bottom: 8px !important;
  }
}

.create-category-modal {
  overflow: visible;

  .dialog-paper {
    overflow: visible;
  }

  .dialog-content {
    overflow: visible;
  }
}

.uploaded-shade-img-overlay {
  border-radius: 4px;
  background-image: linear-gradient(to right, rgba(76, 175, 80, 0), #ffffff);
  position: absolute;
  left: 143px;
  top: 0;
  width: 32px;
  max-width: 32px;
  height: 64px;
  z-index: 4;
  border-radius: 4px;
  float: right;
  clear: none;
}

.fixed_box {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  width: 175px;
  max-width: 175px;
  background-color: #f1f1f100;

  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.068);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.212);
  }
}

/*
.uploaded-scrollable-li{
    display: block;
} */

.uploaded-scrollable {
  z-index: 3;
  width: 175px;
  max-width: 175px;
  border-radius: 4px;
  left: 0;
  top: 0;
  height: 70px;
  overflow-x: auto;
  overflow-y: hidden;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  padding-right: 100px;
}

.uploaded-image-small {
  @extend .cur-p;
  position: relative;
  display: inline-flex;
  margin-right: 8px;
  @include content-size(64, 64);

  .uploaded-img-overlay {
    border-radius: 4px;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.56)
    );
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    border-radius: 4px;
  }

  img {
    @include content-size(64, 64);
    border-radius: 4px;
    object-fit: contain;
    border: 1px solid $white-9;
  }

  .remove-uploaded-image {
    @include content-size(16, 16);
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: 4px;
    z-index: 2;
  }
}

.buttonstertiary {
  width: 100px;
  height: 36px;
  margin: 0 26px 12px 25px;
  padding: 8px 24px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.file-button-upload,
.file-image-small-upload {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

.file-image-small-upload {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 19px 16px;
  border-radius: 4px;
  @include content-size(64, 64);
  min-width: 64px;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 8px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:not(.has-image) {
    border: 1px solid $gray-3;
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid $white-9;
    object-fit: contain;
  }
  > div > svg {
    width: 30px;
    height: 24px;
  }
}

.file-button-upload,
.file-csv-upload,
.file-csv-uploaded {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

.cat_prod_hor_seperator {
  width: 100%;
  height: 0.5px;
  background-color: #d9d9d9;
  border: none;
}

.center {
  width: 50%;
  text-align: center;
  padding: 2px;
  margin: 0 auto;
}

.btn-csv-add {
  @extend .btn-outline-gray;
  @include font-size(14, 20);
  padding: 5px 16px 5px 16px;
  color: #4d4d4d;
  font-weight: 500;
  border-color: #cccccc;
  text-align: center;
  justify-content: center;
}

.toggle_add {
  border: 1px dashed #b2b2b2;

  .csv-replace {
    visibility: hidden;
    height: 0px;
    width: 0px;
    .file-data-wrapper {
      @extend .d-flex, .space-between, .ml6, .mr6, .mb12;
    }
  }

  .csv-add {
    visibility: visible;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    justify-content: center;
    text-align: center;
    align-content: center;
    width: 70%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;

    .txt-csv-add {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      float: left;
      clear: left;
      color: #4d4d4d;
    }
  }
}

.dragover {
  border: 1px dashed #146eb4;
  background-color: rgba(20, 110, 180, 0.05);
}

.toggle_replace {
  border: 0px solid #b2b2b2;
  padding: 0px !important;

  .csv-add {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  .csv-replace {
    visibility: visible;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    justify-content: center;
    text-align: center;
    align-content: center;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    .file-data-wrapper {
      @extend .d-flex, .space-between, .ml6, .mr6, .mb12;
    }
  }
}

.file-csv-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  // height: 120px;
  padding: 38px;

  > input {
    width: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px dashed $white-9;
    object-fit: contain;
  }

  &.md {
    width: 468px;
  }
  /*    &:not(.disabled):hover {
        pointer-events: none;
        opacity: 0.5;
        border-color: #146eb4;
        background-color: rgba(20, 110, 180, 0.05);
    }*/
  /*    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        border-color: #146eb4;
        background-color: rgba(20, 110, 180, 0.05);
    }*/
}

.download-csv-o {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.download-csv-o .text-style-link-color {
  color: #146eb4;
}

.product-bulk-footer {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-weight: 500;

  > link {
    font-weight: 500;
    color: #146eb4;
  }
}

.prod_bulk_item_camera {
  width: 64px;
  height: 64px;
  padding: 19px 16px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  justify-content: center;
  margin-right: 8px;
}

.prod_bulk_title {
  width: 100%;
  height: 40px;
  margin: 20px 0 10.4px;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.prod_bulk_title div {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
  height: 20px;
  padding-top: 0px;
  padding-left: 16px;
}

.prod_bulk_title_1 {
  width: 25%;
  padding-left: 12px;
  margin: auto;
  overflow-x: hidden;
}

.upload-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .close-fullscreen {
    position: fixed;
    top: 28px;
    right: 28px;
    cursor: pointer;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loader-footer {
    position: fixed;
    bottom: 32px;
  }
}

.prod_bulk_title_2 {
  width: 20%;
  /*  padding-top: 20px; */
  padding-left: 8px;
  margin: auto;
  word-break: break-word;
}

.prod_bulk_title_3 {
  width: 15%;
  /*  padding-top: 20px; */
  padding-left: 8px;
  margin: auto;
  word-break: break-word;
}

.prod_bulk_title_4 {
  width: 10%;
  /*  padding-top: 20px; */
  padding-left: 8px;
  margin: auto;
  word-break: break-word;
}

.prod_bulk_title_5 {
  width: 15%;
  /*  padding-top: 20px; */
  padding-left: 8px;
  margin: auto;
  word-break: break-word;
}

.prod_bulk_title_6 {
  width: 15%;
  /*  padding-top: 20px; */
  padding-left: 8px;
  margin: auto;
  word-break: break-word;
}
.prod_bulk_item_6 {
  width: 15%;
  margin: auto;
  word-break: break-word;
}

.prod_bulk_item_title {
  font-weight: 500;
  color: #4d4d4d;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /*
    line-height: 1.43;
    text-align: left;
    height: 20px; */
}

.prod_bulk_item {
  color: #1a181e;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /*
    line-height: 1.43;
    text-align: left;
    height: 20px; */
}

.hover_gray:hover {
  background-color: #f2f2f2;
}

.remove-bulk-product-item {
  color: #e50b20;
  padding-left: 8px;
  padding-right: 10px;
  margin-top: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 24px;
  &:hover {
    color: #bf091b;
  }
}

.edit-bulk-product-item {
  color: #1a181e;
  padding-left: 10px;
  padding-right: 8px;
  margin-top: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 24px;
  &:hover {
    color: #4d4d4d;
  }
}

.hideLine {
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.prod_line {
  height: 1px;
  width: 100%;
  border: none;
  background: #e5e5e5;
}

.bulk-product-form-fill {
  width: auto;
  height: auto;
  padding: 24px;
  margin-bottom: 32px;
}

.bulk_prod_preview_hidden {
  visibility: hidden;
}

.bulk_prod_preview_visible {
  visibility: visible;
}

.product-form-wrap-dialog {
  margin: 16px;
  max-width: 760px;

  .product-images-section {
    margin-bottom: 16px;
    padding: 16px;

    .product-images-wrap {
      overflow-x: auto;
      display: flex;

      .uploaded-image {
        margin-left: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
.update-product-inventory-modal {
  .dialog-paper.sm {
    width: 400px !important;
  }
  .dialog-paper.lg {
    width: 760px !important;
  }
  .table-header-wrapper {
    padding: 10px 12px !important;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.dialog-modal.select-add-on-modal {
  .dialog-paper.md {
    .sticky-header {
      position: sticky;
      top: 0;
      left: 0;
      padding: 24px 0;
      background-color: $white;
      z-index: 20;
      margin-right: -24px;
      margin-left: -10px;
      padding-left: 10px;
    }
    width: 564px;
    .dialog-content {
      padding-top: 0;
      .dialog-close-icon {
        top: 24px;
        right: 24px;
      }
      .select-add-on-modal-content {
        .select-add-on-modal-header {
          @include display-flex(center, space-between);
          padding: 24px 44px 0 0;

          .create-new-button {
            @extend .cur-p;
            @include font-size-weight(16, 24, 500);
            color: $orange-1;
          }
        }

        .add-on-list {
          margin: 0 -8px 0 -8px;
          .add-on-item {
            @extend .cur-p;
            @include font-size(16, 24);
            @include display-flex(center, space-between);

            padding: 8px;
            border-radius: 4px;
            &:hover {
              background-color: $white-6;
            }
          }
        }
      }
    }
  }
}

.dialog-modal.create-add-on-modal {
  .dialog-paper.md {
    width: 564px;
    .dialog-content {
      padding: 24px;
      .dialog-close-icon {
        top: 24px;
        right: 24px;
      }
      .create-add-on-modal-content {
        .create-add-on-form {
          margin-top: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1080px) {
  .products-list {
    grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  }
}
.catalog-builder-banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 16px 24px;
  background-color: #fff;
  border: solid 1px $white-2;
  border-radius: 8px;
}

.catalog-builder-info-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: $white-10;
  border: solid 1px $white-2;
  border-radius: 4px;
}

.info-stripe {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  background-color: $white-10;
  border: solid 1px $white-2;
  border-radius: 4px;
}

.info-stripe-warehouse-payment {
  display: flex;
  width: 100%;
  padding: 12px;
  background-color: rgba(250, 183, 59, 0.1);
  border-radius: 4px;
}

.hyperlocal-info-stripe {
  display: flex;
  justify-content: flex-start;
  max-width: 712px;
  padding: 8px 16px;
  background-color: $white-10;
  border: solid 1px $white-2;
  border-radius: 4px;
}

.orange-info-stripe {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  background-color: rgba(250, 183, 59, 0.1);
  border: solid 1px $orange-4;
  border-radius: 4px;
}

.orange-1-info-stripe {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  background: rgba(238, 116, 31, 0.1);
  border: solid 1px $orange-1;
  border-radius: 4px;
  color: $orange-1;
}

.green-info-stripe {
  width: 100%;
  padding: 8px 16px;
  background: rgba(23, 179, 27, 0.1);
  border-radius: 6px;
  border: solid 1px $green-1;
  color: $green-1;
}

.error-stripe {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  background-color: rgba(229, 11, 32, 0.1);
  color: $red-1;
  border: solid 1px $red-1;
  border-radius: 4px;
}

.error-stripe-international {
  position: fixed;
  bottom: 80px;
  padding: 16px 24px;
  background-color: $red-1;
  color: $white;
  width: 100%;
  @media (min-width: 768px) {
    width: calc(100% - 223px);
    bottom: 0px;
  }
}

.category-card {
  padding: 16px 0px 16px 24px;
  background-color: $white-10;
  border: solid 1px $white-2;
  border-radius: 4px;
  cursor: pointer;
}

.catalog-builder-categories {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
}

.select-catalog-products {
  margin: 32px auto;
  max-width: 956px;
}

.catalog-builder-header {
  max-width: 956px !important;
}

.select-catalog-products-search {
  max-width: 200px;
  margin-bottom: 24px;
}

.catalog-builder-product-image {
  height: 80px;
  width: 80px;
  border-radius: 6px;
  object-fit: contain;
  border: solid 1px $white-8;
}

.category-products-sticky {
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 12px;
  z-index: 40;
}

.catalog-catagory-image {
  @include content-size(216, 134);
}

.catalog-sidebar {
  min-width: 307px;
  width: 307px;
  height: 100%;
  padding-top: 18px;
  position: sticky;
  top: 0;
}

.catalog-products {
  @extend .fill-available;
  border-left: solid 1px $white-8;
}

.suggestion-images-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(108px, 1fr));
  grid-gap: 8px;
}

.suggested-image-container {
  @include content-size(112, 112);
  @extend .cur-p;
  overflow: hidden;
  border: solid 1px $white-8;
  border-radius: 8px;
  position: relative;

  .suggested-image-preview {
    @include content-size(112, 112);
    object-fit: contain;
  }

  .selection-number {
    @include content-size(20, 20);
    @extend .d-flex-c-c;
    background-color: $purple-1;
    border-radius: 10px;
    border: solid 1px white;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;

    span {
      @include font-size(14, 20);
      color: white;
    }
  }
}

.suggested-theme-aspect-ratio {
  @include content-size(112, 140);
  .suggested-image-preview {
    @include content-size(112, 140);
    object-fit: contain;
  }
}

.suggestion-images-loader {
  min-height: 200px;
}

.product-description-link {
  @extend .text-medium, .c-black-3, .d-flex;
  margin-top: -8px;
}

.upload-bulk-icon {
  border: 1px solid $gray-3;
  & div > img {
    height: 24px;
    width: 30px;
  }
}

.category-wrapper {
  &.attribute-wrap {
    position: relative;
    .category-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      position: absolute;
      margin-left: 16px;
      margin-right: 16px;
      top: 42px;
      width: -webkit-fill-available;
      background-color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.additional-information-panel-row {
  gap: 24px;
  .additional-information-panel-col {
    max-width: calc(50% - 24px);
    @include calculated-spacer(
      0 0 calc(50% - 12px)
    ); // input field gaps = 24px, gap/2 = 12px
    .additional-panel-vendor {
      .form-group {
        @extend .mb0;
      }
    }
  }
}

.brand-select {
  .custom-select__control {
    padding-right: 8px;
  }
  .css-tlfecz-indicatorContainer {
    color: $black-3;
  }
  .custom-select__indicator {
    margin-right: 6px;
  }
}

.uploaded-file-image {
  column-gap: 8px;
  img {
    height: 24px;
    width: 24px;
    border-radius: 2px;
  }
  div {
    height: 24px;
  }
  .uploaded-img-overlay {
    display: none;
  }
}

.uploaded-file-input-placeholder {
  color: $gray-4;
}

.product-file-attribute-icon {
  svg {
    height: 24px;
    width: 24px;
  }
}
.vendor-select {
  &.custom-select__single-value {
    color: $gray-4;
  }
}

.products-filter-menu {
  overflow-y: scroll;
  .MuiMenuItem-root {
    width: 42%;
  }
}
