.app-layout {
  display: flex;
  height: 100%;
  .app-layout-content {
    background-color: $white-1;
    flex-grow: 1;
    height: 100%;
    min-height: 100%;
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;
  }
  .main-header {
    &.MuiPaper-root {
      background-color: $white;
      color: $body-color;
      box-shadow: none;
      border-bottom: 1px solid $white-2;
      @media (min-width: 600px) {
        .header-menu-icon {
          display: none;
        }
      }
    }
    .header-menu-icon {
      > svg {
        @include content-size(18, 18);
        cursor: pointer;
        margin-right: 12px;
        position: relative;
        top: 1px;
      }
    }
    .navbar-header {
      width: max-content;
      z-index: 2;
      &:focus {
        outline: none;
      }
      display: flex;
      align-items: center;

      .store-image {
        height: 40px;
        width: 40px;
        max-width: 160px;
        margin-right: 12px;
        border-radius: 4px;
        object-fit: contain;
      }
      .header-label {
        width: fit-content;
        padding: 10px;
        margin-left: -10px;
        .title-chevron {
          @extend .d-flex, .align-center;
          width: 24px;
          height: 24px;
          margin-top: 2px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .header-back-link-wrap {
      @include circle(44px);
      padding: 8px 10px 12px;
      margin-right: 14px;
      transition: background-color 0.3s;
      &:hover {
        background-color: $white-6;
      }
    }
    .header-back-link {
      // @include content-size(24, 24);
      cursor: pointer;
      position: relative;
      // top: 2px;
    }
    .header-back-link {
      cursor: pointer;
      margin-right: 14px;
      margin-left: -10px;
      position: relative;
    }
    .header-offline-container {
      user-select: none;
      position: fixed;
      width: calc(100% - 224px);
      bottom: 55px;
      z-index: 2;
      .header-offline-badge {
        font-size: 14px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #e50b20;
        color: #ffffff;
        padding: 8px 24px;
        box-shadow: 0px 10px 20px 0px #9898984d;
        border-radius: 20px;
      }

      .go-online {
        text-decoration-line: underline;
        font-weight: 500;
        margin-left: 4px;
        cursor: pointer;
      }
    }
    .header-offline-container-on-trial {
      bottom: 120px;
    }
    .header-toolbar {
      padding: 16px 32px;
      height: $toolbar-height;
      display: flex;
    }
    .header-title-wrap {
      @include display-flex(center, flex-start);
      flex-flow: row wrap;
      position: relative;
      width: 100%;
      position: relative;
      .header-actions {
        margin-left: auto;
      }
    }
    .header-search-bar {
      position: absolute;
      left: calc(50% - 200px);
      .MuiBackdrop-root {
        z-index: 1;
      }
    }
    .header-menu-divider {
      width: 1px;
      height: 24px;
      background-color: $gray-3;
      margin: 0 24px;
    }
    .earn-refer-header {
      height: 40px;
      width: 220px;
      svg {
        margin-top: -2px;
      }
      &:hover {
        background-color: $white-6;
        border-radius: 4px;
      }
    }
    &.full-width-header {
      width: 100%;
    }
  }
  .main-header {
    @media (min-width: 600px) {
      width: calc(100% - #{$sidebar-width});
      margin-left: $sidebar-width;
    }
  }
  &.transaction-past-due-layout {
    .main-header {
      @media (min-width: 600px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  &.transaction-due-layout {
    .app-layout-content {
      @media (min-width: 600px) {
        width: calc(100% - #{$sidebar-width});
        margin-left: $sidebar-width !important;
      }
    }
  }
}

.online-offline-badge {
  .onoffswitch {
    position: relative;
    width: 79px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch.offline {
    width: 85px;
  }
  .switchposition {
    left: 4px;
  }
  .onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 17px;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 28px;
    padding: 0;
    line-height: 28px;
    color: white;
    font-size: 14px;
    letter-spacing: -0.72px;
    box-sizing: border-box;
  }
  .onoffswitch-inner:before {
    content: 'Open';
    padding-left: 12px;
    background-color: #17b31b;
    color: #ffffff;
    font-weight: 500;
  }
  .onoffswitch-inner:after {
    content: 'Closed';
    padding-right: 12px;
    background-color: #e50b20;
    color: #ffffff;
    text-align: right;
    font-weight: 500;
  }
  .onoffswitch-switch {
    display: block;
    width: 20px;
    background: #ffffff;
    position: absolute;
    border-radius: 17px;
    top: 4px;
    height: 20px;
    border-radius: 50%;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 4px;
  }
}

.how-it-works-store-settings {
  padding: 4px 6px;
  border: 1px solid $white-2;
  border-radius: 12px;
}

.festive-discount-banner {
  background: radial-gradient(
    68.13% 61318.26% at 6.21% 50%,
    rgba(79, 3, 138, 0.9) 0%,
    rgba(27, 0, 80, 0.85) 100%
  );
}

.how-it-works {
  padding: 4px 6px;
  border: 1px solid $white-2;
  border-radius: 12px;
  white-space: nowrap;
  @include font-size-weight(12, 16, 400);
  color: $black-3;
  opacity: 1;

  &:hover {
    opacity: 0.7;
    transition: 0.15s ease-in-out;
  }
}

.app-banner {
  @include display-flex;
  @include font-size(14, 20);
  color: $white;
  height: 40px;
}

.app-bottom-nav {
  @include display-flex(center, space-between);
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 224px);
  margin-left: 224px;
  box-shadow: inset 0 1px 0 0 $white-2;
}

.subscription-expiring-banner {
  @extend .app-bottom-nav;
  background-color: $white;
  padding: 16px 32px;
  z-index: 1200;
}

.subscription-expired-banner {
  @extend .app-banner;
  background-color: $red-1;
}

.left-to-right-collapse {
  &.MuiCollapse-container {
    width: 0;
    transition-property: width;
  }

  &.MuiCollapse-entered {
    width: 30%;
  }

  &.MuiCollapse-hidden {
    width: 0;
  }
}

.header-back-link-background {
  margin-left: -11px;
  margin-right: -6px;
  circle {
    fill: $white-1;
  }
}
.header-back-link-active-background {
  margin-left: -11px;
  margin-right: -6px;
  circle {
    fill: $white-9;
  }
}

.empty-container {
  position: relative;
  height: 300px;
}

.no-invoice-data {
  height: 116px;
}

.app-bar-menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.popover {
  margin-right: 8px;
  margin-top: 8px;
  z-index: 1101;
  bottom: 0;
}

.report-an-issue {
  white-space: nowrap;
  padding-right: 24px;
  border-right: 2px solid $white-2;
  margin-right: 24px;
  color: $black-3;
  &:hover {
    text-decoration: underline;
    transition: 0.15s ease-in-out;
    color: $black-3;
  }
}

iframe.skiptranslate {
  display: none;
}
#goog-gt-tt {
  display: none !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
