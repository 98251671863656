@mixin modal-size($size) {
  width: #{$size}px;
  max-width: #{$size}px;
}

.dialog-modal {
  .dialog-backdrop {
    background-color: rgb(0, 0, 0) !important;
    opacity: 0.6 !important;
  }

  .info-dialog {
    width: 405px !important;
    .leave-btn:hover {
      color: $red-1;
    }
  }

  .dialog-paper {
    @include box-style;

    &.xs {
      @include modal-size(360);
    }

    &.xm {
      @include modal-size(400);
    }

    &.xm-1 {
      @include modal-size(490);
    }

    &.sm {
      @include modal-size(564);
    }

    &.md {
      @include modal-size(640);
    }
    &.md-760 {
      @include modal-size(760);
    }

    &.md-760 {
      @include modal-size(760);
    }

    &.md-lg {
      @include modal-size(1200);
    }

    &.lg {
      @include modal-size(1200);
    }

    &.s-960 {
      @include modal-size(960);
    }

    &.xl {
      @include modal-size(1152);
    }

    &.modal-visible {
      overflow-y: visible;

      .dialog-content {
        overflow-y: visible;
      }
    }
  }

  &.tags-modal {
    .dialog-content.MuiDialogContent-root {
      padding-bottom: 16px;
      padding-left: 20px;
    }

    .dialog-paper.sm {
      max-height: 92vh;
    }

    .dialog-content {
      p,
      .search-filter {
        margin-left: 4px;
      }
    }

    .loader-container {
      padding: 9px 0;
      height: calc(92vh - 310px);
      max-height: 843px;
      display: flex;
      align-items: center;
    }

    .tags-modal-grid-wrapper {
      grid-template-columns: repeat(2, 262px);
      gap: 6px;
      .checkbox-label-wrap {
        padding: 4px;
        border-radius: 4px;

        &:hover {
          background-color: $white-6;
        }
      }
    }

    .tags-modal-options-container {
      padding: 9px 0;
      max-height: calc(92vh - 310px);
    }

    .pagination {
      margin: 13px 0;
    }
  }

  .dialog-content.MuiDialogContent-root {
    color: $body-color;
    padding: 22px;
    max-height: 92vh;
    overflow-x: hidden;

    .dialog-close-icon {
      position: absolute;
      right: 14px;
      top: 18px;
      width: 28px;
      height: 28px;

      svg {
        width: 20px;
        height: 20px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 9999;

      &:hover {
        background-color: #f2f2f2;
        border-radius: 4px;
      }
    }
    .dialog-title-text {
      position: absolute;
      left: 16px;
      top: 16px;
      cursor: pointer;
      @include font-size-weight(18, 25.92, 500);
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $black-1;
    }
  }

  .dialog-footer {
    justify-content: flex-start;
    padding: 0 24px 32px;

    button {
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
}

.alert-modal,
.confirm-modal,
.shipment-created-modal,
.address-missing-modal,
.bulk-action-error-modal {
  .dialog-content {
    padding: 22px !important;
    text-align: center;
  }

  .dialog-footer {
    // justify-content: center;
    padding: 0 22px 22px;
  }
}

.cancel-refund-modal {
  .order-product {
    img {
      width: 48px;
      height: 48px;
      border-radius: 6px;
    }
    .refund-input {
      width: 120px;
      .form-group {
        margin: 0;
      }
      input {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.fullscreen-dialog-paper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #221d1d12 !important;

  .fullscreen-dialog-close-container {
    padding: 24px 24px 0 24px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      cursor: pointer;
      path {
        fill: white;
      }
    }
  }
}
.fullscreen-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.return-shipment-images-modal {
  max-width: 100vw;
  padding-bottom: 24px;

  .BrainhubCarousel__trackContainer {
    width: 100%;
  }

  .BrainhubCarouselItem {
    min-width: 100%;
  }
  .BrainhubCarousel__container {
    min-width: 90vw;
    img {
      height: 70vh;
      width: auto;
      object-fit: contain;
      max-width: 80%;
    }
  }
}
.order-detail-modal .dialog-content.MuiDialogContent-root{
  overflow: hidden !important;
}
.shipment-created-modal {
  .dialog-title-text {
    padding: 7px 7px 0;
  }

  .dialog-content {
    width: 100%;
    text-align: left;

    .header-svg-icon {
      width: 100%;
      text-align: center;
      margin-bottom: 12px;
    }

    .ship-success-header {
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;

      .ship-header-2 {
        @include font-size-weight(18, 25.92, 500);
        color: $black-1;
      }

      .ship-header-6 {
        @include font-size-weight(16, 24, normal);
        color: $black-3;
      }

      .ship-details-table-container {
        width: 100%;
        padding: 16px;
        border-radius: 4px;
        border: solid 1px $white-2;
        background-color: $white-1;
        margin-top: 24px;

        .ship-details-table {
          width: 100%;
          display: table;

          .ship-details-table-row {
            display: flex;
            margin-left: -16px;
            margin-right: -16px;

            .ship-details-table-column {
              padding: 0 16px;
              width: 50%;
              color: $black-1;

              .link {
                color: $purple-1;
                text-decoration: underline;
                user-select: none;
                cursor: pointer;

                &.disabled {
                  opacity: 0.6;
                  cursor: wait;
                }
              }

              text-align: left;
            }
          }
        }
      }
    }
  }

  .shipment-success-btn {
    padding: 8px 24px;
  }
}

.shipment-details-modal {
  .dialog-title-text {
    padding: 7px 7px 0;
  }
}
.preview-changes-modal .dialog-close-icon{
  top: 22px !important
}
.preview-changes-modal {
  .dialog-content.MuiDialogContent-root {
    padding: 13px 0 0 !important;
    text-align: center;
    height: 92vh;
    overflow: hidden; 
  }

  .publish-btn {
    display: flex;
    padding-right: 72px;
  }

  .iframe-wrapper {
    background: $white-1;
    .preview-iframe {
      &.desktop {
        width: 1280px;
        height: 100vh;
        scale: 0.9;
        transform-origin: left top;
      }

      &.mobile {
        height: calc(92vh - 72px);
        width: 360px;
      }
    }
  }
}

.track-order-modal {
  .dialog-title-text {
    padding: 8px 7px 0;
  }

  .stepper-container {
    padding-top: 20px;
  }

  .border-bottom {
    border-bottom: 1px solid $white-2;
  }

  .estimated-time-container {
    &:first-child {
      margin-bottom: 12px;
    }

    margin-top: 15px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid $white-2;

    .clock-icon {
      font-size: 24px;
    }
  }

  .delivery-boy-details {
    border-top: 1px solid $white-2;
    padding-top: 16px;
  }
}

.confirm-modal {
  .dialog-content {
    .header-img,
    > .header-svg-icon {
      @include content-size(48, 48);
      margin-bottom: 12px;
    }
  }
}

.accept-order-modal {
  .time-slot-wrapper {
    .time-slot {
      @include font-size(14, 20);
      padding: 5px 12px;
      text-align: center;
      display: inline-block;
      margin: 0 8px 8px 0;
      border: solid 1px $white-2;
      border-radius: 4px;
      cursor: pointer;
      outline: none;
      color: $black-3;

      &.selected,
      &:hover,
      &:active,
      &:focus {
        border: solid 1px $purple-1;
        color: $purple-1;
        font-weight: 500;
        background-color: rgba($purple-1, 0.1);
      }
    }
  }
}

.bulk-loader {
  .MuiDialog-paperFullScreen {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.go-online-modal {
  .MuiDialogContent-root.dialog-content {
    padding: 24px;
  }

  .time-selection-wrapper {
    padding: 24px 0px;

    .MuiRadio-colorSecondary.Mui-checked {
      color: $purple-1;
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba(20, 110, 180, 0.04);
    }
  }

  .re-opens-message {
    font-size: 14px;
    color: $gray-1;
    font-weight: normal;
  }

  .re-opens-time {
    font-weight: 500;
  }

  .btn-confirm {
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 500;
  }

  .time-entry {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $black-1;
  }
}

.dialog-modal.delete-modal {
  .dialog-paper {
    @include modal-size(600);

    .dialog-content {
      padding: 24px;

      .dialog-close-icon {
        right: 24px;
        top: 16px;
      }

      .delete-modal-content {
        @extend .d-flex-c-c;
        flex-direction: column;
      }
    }
  }
}

.dialog-modal.share-link-modal {
  .dialog-paper {
    @include modal-size(400);

    .dialog-content {
      padding: 24px;

      .dialog-close-icon {
        right: 24px;
      }

      .dialog-title-text {
        left: 24px;
        top: 24px;
      }

      .share-link-modal-content {
        @extend .d-flex-c-c;
        flex-direction: column;

        .share-via {
          margin-top: 32px;

          ul {
            margin: 0;
          }
        }
      }
    }
  }
}

.dialog-modal.product-hide-show-modal {
  .dialog-paper {
    @include modal-size(400);

    .dialog-content {
      padding: 24px 16px 24px 16px;

      .dialog-close-icon {
        right: 24px;
        top: 16px;
      }

      .actions-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 16px;

        .action-row {
          display: flex;
          flex-direction: row;
          padding: 8px 8px 3px 8px;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: $white-6;
          }
        }

        .action-container {
          margin-left: 12px;

          .action {
            font-size: 16px;
            font-weight: 500;
            color: $black-3;
          }

          .description {
            font-size: 14px;
            line-height: 1.43;
            color: $gray-1;
            width: 312px;
          }
        }
      }
    }
  }
}

.dialog-modal.download-report-modal {
  .dialog-paper {
    @include modal-size(400);

    .dialog-content {
      padding: 24px;

      .dialog-close-icon {
        right: 24px;
        top: 18px;
      }

      .dialog-title-text {
        right: 24px;
        top: 24px;
      }

      .download-via {
        @extend .d-flex;
        flex-direction: row;
        margin-top: 24px;
        justify-content: space-around;

        .divider {
          width: 1px;
          height: 74px;
          background-color: $white-2;
        }

        .tile {
          @extend .d-flex;
          cursor: pointer;
          align-items: center;
          flex-direction: column;
          flex: 1;

          span {
            margin-top: 2px;
            color: $black-3;
          }
        }

        .order-receipt-type-icon {
          padding: 23px;
          width: 100%;
          border: solid 1px $white-2;
          border-radius: 4px;

          &:hover {
            background-color: $white-10;
          }
        }
      }
    }
  }
}

.dialog-modal.abandoned-recovery-modal {
  .dialog-paper {
    .dialog-content {
      padding-bottom: 16px;
    }
  }
}

.dialog-modal.download-report-modal.order-report-modal {
  .dialog-paper {
    @include modal-size(760);
  }
}

.info-modal {
  .wrapper {
    @extend .d-flex-c-c;
    flex-direction: column;
  }

  .title {
    @include font-size-weight(18, 26, 500);
    text-align: center;
    color: $black-1;
    margin: 24px 100px 6px;
  }

  .message {
    @include font-size-weight(16, 24, 400);
    margin: 6px 0 0;
    text-align: center;
    color: $black-3;
  }
}

div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
}

div::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #ffffff;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.082); */
}

div::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(161, 161, 161, 0.534);
  /*  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.637); */
}

div::-webkit-scrollbar-thumb:hover {
  background: rgba(112, 111, 111, 0.74);
}

.dialog-modal.choose-unit-modal {
  .dialog-content {
    padding-top: 24px;
    padding-bottom: 18px;
  }

  .header {
    @include font-size-weight(18, 26, 500);
    text-align: center;
    margin-bottom: 16px;
  }

  .units-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .unit {
      @include font-size-weight(14, 20, 400);
      color: $black-3;
      padding: 6px 12px;
      border: solid 1px $white-2;
      border-radius: 4px;
      margin-bottom: 8px;
      margin-right: 8px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &.active {
        color: $purple-1;
        border-color: $purple-1;
        background-color: rgba(20, 110, 180, 0.1);
      }
    }
  }
}

.courier-table,
.ship-options-list {
  margin-top: 24px;
}

.courier-row,
.courier-header {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  align-items: center;
}

.courier-row-container {
  padding: 0 12px;
  width: 100%;

  .courier-row {
    padding: 14px 0;

    .courier-name {
      @include font-size-weight(16, 24, 500);
    }

    .actionCol {
      button {
        padding: 4px 12px;
        border-radius: 4px;
        background-color: $purple-1;
        @include font-size-weight(14, 20.02, 500);
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
    }

    &:not(:last-of-type) {
      border-bottom: $white-2 1px solid;
    }
  }
}

.courier-header {
  padding: 8px 12px;
  @include font-size-weight(16, 24, 500);
  background-color: $white-6;
}

.delivery-partner-header {
  padding: 8px 0px;
  @include font-size-weight(14, 20, 500);
  color: $black-3;
  background-color: $white-6;
  border-radius: 4px;
}

.delivery-partner-row,
.delivery-partner-header {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  // align-items: center;
}

.delivery-partner-row-container {
  .delivery-partner-row {
    padding: 24px 0 12px 0;
    cursor: pointer;
    max-height: 83px;
    min-height: 83px;

    .delivery-partner-name-box {
      .MuiRadio-root.custom-radio {
        padding: 0 12px 0 0 !important;
      }

      @include font-size-weight(14, 20, 400);
    }

    .actionCol {
      button {
        padding: 4px 12px;
        border-radius: 4px;
        background-color: $purple-1;
        @include font-size-weight(14, 20.02, 500);
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
    }

    &:not(:last-of-type) {
      border-bottom: $white-2 1px solid;
    }
  }
}

.ship-options-list {
  padding-top: 16px;
  margin-top: 16px;

  .ship-option {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    align-items: center;
    position: relative;

    &:nth-child(even) {
      &::before {
        content: '';
        background: $white-2;
        width: 1px;
        height: 75%;
        position: absolute;
        left: 0;
      }
    }

    .ship-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      padding: 12px 24px;

      &:hover {
        background: #f2f2f2;
        border-radius: 4px;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    svg {
      width: 48px;
      height: 48px;
    }

    h6 {
      @include font-size-weight(16, 24, 500);
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: $black-3;
    }

    img {
      margin: auto;
    }
  }
}

.modal-loader {
  height: 74px;
  margin-top: 32px;
  position: relative;
}

.expiry-modal {
  &:not(.no-blur) {
    .dialog-backdrop {
      -webkit-backdrop-filter: blur(24px) !important;
      backdrop-filter: blur(24px) !important;
      background-color: rgba($black, 0.6) !important;
      opacity: 1 !important;
    }
  }

  .dialog-paper.sm {
    @include modal-size(760);

    .dialog-content {
      padding: 48px;
    }

    a.upgrade-button {
      @include font-size-weight(16, 24, 500);
      padding: 8px 12px;
    }
  }
}

.premium-content-modal {
  .dialog-backdrop {
    background-color: rgba($black, 0.6) !important;
    opacity: 1 !important;
  }

  .dialog-paper.sm {
    @include modal-size(760);

    .dialog-content {
      padding: 48px;
    }

    a.upgrade-button {
      @include font-size-weight(16, 24, 500);
      padding: 8px 12px;
    }
  }
}

.select-category-modal {
  max-height: 90vh;
  .dialog-content {
    height: 100%;
    max-height: 100% !important;
    .category-list {
      max-height: calc(100vh - 352px);
      overflow-y: auto;
      padding-top: 12px;
      padding-bottom: 12px;
      &.overflow-overlay {
        padding: 12px 24px;
        margin: 0 -24px;
        overflow-y: overlay;
      }
      .category-option-container {
        @include display-flex(center, space-between);
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .category-option {
          @include font-size(16, 24);
          @include display-flex(center, flex-start);
          cursor: pointer;
          outline: none;
          width: 100%;
          .category-option-image {
            height: 48px;
            width: 48px;
            border: 1px solid $white-9;
            border-radius: 4px;
          }
          .category-option-desc {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
          }
        }
        &.child-selected {
          border: solid 1px $purple-1;
          color: $purple-1;
          font-weight: 500;
          background-color: #fff;
        }
      }
      .subcategory {
        padding-left: 24px;
      }
      .MuiFormControlLabel-label {
        display: none;
      }
    }
  }
  .hr-line {
    height: 1px;
    background-color: $white-2;
    border: none;
    margin-left: -24px;
    margin-right: -24px;
  }
}

.select-product-modal {
  max-height: 90vh;
  .dialog-content {
    height: 100%;
    max-height: 100% !important;
    padding-bottom: 16px !important;
    .product-list {
      max-height: calc(100vh - 352px);
      overflow-y: overlay;
      padding-top: 12px;
      padding-bottom: 12px;
      .product-option-container {
        @include display-flex(center, space-between);
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .product-option {
          @include font-size(16, 24);
          @include display-flex(center, flex-start);
          cursor: pointer;
          outline: none;
          width: 100%;
          .product-option-image {
            object-fit: contain;
            height: 48px;
            width: 48px;
            border: 1px solid $white-9;
            border-radius: 4px;
          }
          .product-option-desc {
            display: flex;
            flex-direction: column;
            margin-left: 16px;

            .product-cost {
              font-weight: 500;
            }
            .original-cost {
              text-decoration: line-through;
              color: $gray-1;
              margin-left: 4px;
            }
          }
        }
        &.child-selected {
          border: solid 1px $purple-1;
          color: $purple-1;
          font-weight: 500;
          background-color: #fff;
        }
      }
      .subcategory {
        padding-left: 24px;
      }
      .MuiFormControlLabel-label .checkbox-label {
        padding: 0;
      }
    }
  }
  .hr-line {
    height: 1px;
    background-color: $white-2;
    border: none;
    margin-left: -24px;
    margin-right: -24px;
  }

  .select-product-search-filter {
    svg:first-of-type {
      position: absolute;
      top: calc(50% - 10px);
      left: 13px;
    }
  }
}

.select-attribute-modal {
  max-height: 90vh;
  .dialog-content {
    height: 100%;
    max-height: 100% !important;
    .attribute-list {
      max-height: calc(100vh - 352px);
      overflow-y: auto;
      padding-top: 12px;
      padding-bottom: 10px;
      padding-right: 10px;
      .attribute-option-container {
        @extend .d-flex;
        justify-content: space-around;
        flex-direction: column;
        .attribute-option {
          @include font-size(16, 24);
          @include display-flex(center, flex-start);
          cursor: pointer;
          outline: none;
          width: 100%;
          .attribute-option-desc {
            width: 468px;
            overflow-wrap: break-word;
          }
        }
        &.child-selected {
          border: solid 1px $purple-1;
          color: $purple-1;
          font-weight: 500;
          background-color: #fff;
        }
        .attribute-value {
          width: 460px;
          overflow-wrap: break-word;
        }
      }
      .MuiFormControlLabel-label {
        display: none;
      }
    }
  }
  .hr-line {
    height: 1px;
    background-color: $white-2;
    border: none;
    margin-left: -24px;
    margin-right: -24px;
  }
  .edit-attribute-btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.attribute-plugin-modal {
  .download-template-btn {
    @extend .btn-primary-4, .mt4;
    @include font-size-weight(14, 20, 500);
    padding: 7px 16px;
  }
  .csv-replace {
    .file-data-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .product-bulk-footer {
      max-width: 300px;
      text-align: left;
    }
    .btn-csv-add {
      padding: 7px 16px;
    }
  }
}

.get-premium-modal {
  .dialog-paper.sm {
    @include modal-size(760);

    .dialog-content {
      padding: 48px;
    }

    .btn-primary-0 {
      padding: 8px 12px;
    }
  }
}

.suggested-images-modal {
  .dialog-paper.sm {
    @include modal-size(760);
  }
}

.contact-card {
  margin-top: 16px;
  width: 210px;
  height: 62px;
  background: $white-10;
  border: 1px solid $white-9;
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  justify-content: center;
  text-align: center;
  align-content: center;
}

.scrolling-modal {
  max-height: calc(92vh - 215px);
  overflow-y: overlay;
  padding: 3px 24px 0px;
  margin: -3px -24px 0;
}

.custom-domain-modal {
  .store-image-container {
    height: 64px;
    width: auto;
    border-radius: 6px;

    .store-image {
      border: solid 1px $white-8;
      height: 100%;
      width: 100%;
    }
  }
}

.min-height-modal {
  min-height: 300px;
}

.luxury-appearence-modal {
  .MuiFormControlLabel-label {
    font-weight: 500;
  }
}

.reorder-modal {
  .dialog-content {
    max-height: 680px;
    display: flex;
    flex-direction: column;

    .redorder-modal-header {
      flex: 1 1 auto;
      @extend .section-text-4;
    }

    .hr-line {
      height: 1px;
      background-color: $white-2;
      border: none;
      margin-left: -24px;
      margin-right: -24px;
    }

    .reorder-modal-content {
      overflow-y: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      flex: 2 2 auto;

      .show-shimmer {
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .reordering-card {
        border: 1px solid $white-2;
        box-sizing: border-box;
        border-radius: 4px;

        .reordering-card-icon {
          width: 54px;
          height: 54px;
          border-right: 1px solid $white-2;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        .drag-icon {
          padding: 0 16px;
          position: relative;

          div {
            background: $black-3;
            border-radius: 4px;
            width: 20px;
            height: 2.5px;
            margin: 2.5px;
          }

          &::before {
            content: '';
            border-left: solid $white-2 1px;
            position: absolute;
            left: 0px;
            top: -6px;
            height: 24px;
          }
        }

        &.not-draggable {
          background-color: $white-10;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .reorder-modal-footer {
      text-align: center;
      flex: 1 1 auto;
    }
  }
}

.reorder-product-modal {
  .with-scroll {
    padding-right: 12px;
    margin-right: -24px;
  }
  .dialog-content {
    max-height: 680px;
    display: flex;
    flex-direction: column;

    .redorder-modal-header {
      flex: 1 1 auto;
      @extend .section-text-4;
    }

    .reorder-product-title {
      @extend .line-clamp-2, .text-medium, .text-3, .my-auto;
      width: 55%;
    }

    .hr-line {
      height: 1px;
      background-color: $white-2;
      border: none;
      margin-left: -24px;
      margin-right: -24px;
    }

    .reorder-modal-content {
      overflow-y: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      flex: 2 2 auto;

      .show-shimmer {
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .reordering-card {
        padding: 12px 0 12px 0;

        .reordering-card-icon {
          width: 48px;
          height: 48px;
          border: 1px solid $white-9;
          border-radius: 4px;
        }

        &.hr-break {
          border-bottom: solid $white-9 1px;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .reorder-modal-footer {
      height: 68px;
      margin-bottom: -8px;
    }
  }
}

.horizontal-line {
  border-bottom: solid #d9d9d9 1px;
  margin: 16px -24px 8px;
}

.reorder-index {
  width: 32px;
}

.reorder-in-stock {
  width: 83px;
}

.custom-dropdown-modal-container {
  .options-list {
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 40vh;
    overflow: auto;
  }

  .clear-icon {
    width: 16px;
    height: 16px;

    path {
      fill: #999999;
    }
  }

  .add-btn {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.shipment-unserviceable-modal {
  .dialog-title-text {
    margin-left: 8px;
  }

  .shipment-unserviceable-container {
    width: 100%;
    max-height: 70vh;
    overflow: scroll;
  }
}

.border-gray {
  border: 1px solid #e6e6e6;
}

.remove-horizontal-border {
  border-left: none;
  border-right: none;
}

.ship-order-via-modal-content {
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
}

.store-profile-modal {
  .MuiDialog-scrollPaper {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .MuiDialog-paper {
    margin: 16px;
  }
}

.new-shipment-modal {
  .new-shipment-modal-container {
    min-height: 300px;

    .save-delivery-charge {
      background: rgba(23, 179, 27, 0.1);
      border-radius: 3px;
      padding: 1px 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #17b31b;
    }
  }

  .new-shipment-container {
    min-height: 220px;
  }

  .shipment-weight-input input {
    padding: 7px 12px;
  }

  .new-shipping-partners {
    border-radius: 6px !important;

    .partner-tab {
      .MuiTab-root {
        padding: 0px 8px 4px 8px;
        font-size: 16px;
        font-weight: 400;

        &.Mui-selected {
          font-weight: 500;
        }
      }
    }
  }

  .checkbox-label-wrap {
    .custom-checkbox {
      width: 16px;
      height: 16px;
    }
  }
}

.bulk-ship-modal {
  flex-direction: row;
}

.loading-percentage {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.choose-store-email-modal {
  .full-width-divider {
    margin: 0 -24px;
  }
  .dialog-close-icon {
    top: 16px !important;
  }
}
.modal-plan-domain-renewal {
  .offer-badge {
    @extend .text-10;
    font-weight: 600;
    color: $white;
    background-color: $orange-1;
    padding: 6px 16px;
    position: absolute;
    top: 12px;
    left: -8px;
    border-radius: 3px 3px 3px 0px;

    &::after {
      content: '';
      border: 4px solid transparent;
      position: absolute;
      bottom: -8px;
      left: 0;
      border-right-color: $orange-6;
      border-top-color: $orange-6;
    }
  }
}

.dialog-modal.plan-domain-renewal {
  .dialog-paper {
    overflow: inherit;
  }

  .border {
    width: 100%;
    height: 1px;
    background-color: $white-2;
    flex: 1;
  }

  .dialog-close-icon {
    margin-top: -10px;
    margin-right: -8px;
  }
}

.caution-points {
  @extend .text-10;
  background-color: #e50b201a;
  opacity: 1;
  padding: 12px 8px;
  color: $red-1;
  border-radius: 6px;

  ul {
    list-style: disc;
    margin-left: 14px;
  }
}

.import-customer-modal-loader {
  min-height: 266px;
  width: 400px;
}

.mobile-modal {
  position: absolute !important;
  width: 100% !important;
  bottom: 0;
  left: 0;
  border-radius: 8px 8px 0 0 !important;
  margin: 0 !important;

  .dialog-content {
    padding: 24px 16px 16px 16px !important;

    &.show-scrollbar {
      overflow-x: hidden;
    }
  }
}

.dialog-modal.product-description-modal {
  .dialog-content.MuiDialogContent-root {
    padding-bottom: 0px;
  }
  .dialog-content {
    & > div:first-child {
      @include display-flex(center, space-between);
      height: 26px;
      .dialog-title-text {
        position: static;
        cursor: unset;
      }
      .dialog-close-icon {
        position: static;
      }
    }
    .product-description-modal-content {
      p {
        color: $black-3;
      }
      input {
        border: 1px solid #d9d9d9;
        background: $white-10;
        height: 48px;
      }
      .generated-description-section {
        .form-group {
          margin-bottom: 0;
        }
        .form-group .field-label {
          font-weight: 400;
        }
        .description-textarea {
          display: block;
          height: 240px;
        }
      }
      .prod-desc-gt {
        padding: 75px 116px;
        box-sizing: border-box;
        height: 324px;
      }
    }
  }
  .dialog-footer {
    justify-content: center;
    padding: 0 24px 24px;
    button:first-child {
      height: 40px;
    }
  }
}
.import-sub-modal-item {
  width: 164px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $white-2;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: $white-10;
  }
}
.custom-domain-confirmation-modal {
  .dialog-footer {
    flex-direction: column;
  }
}

.abandoned-flow {
  .information-banner-red {
    p {
      &:nth-child(2) {
        color: $black-3;
      }
    }
  }
}

.reactCropperModal {
  max-height: calc(92vh - 226px);
  overflow-y: auto !important;
}

.modal-top-banner {
  background-color: $orange-4;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.update-language-details-modal {
  .scroll-container {
    max-height: calc(92vh - 272px);
    overflow-y: overlay;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.salesbreakdown-modal {
  .breakdown-header {
    background-color: $white-6;
    border-radius: 4px;
    padding: 10px 12px;
    @include d-grid(3fr 1fr 1fr, auto, 12px, flex-start, flex-start);
    & > div:last-of-type {
      justify-self: flex-end;
    }
  }
  .breakdown-rows-container {
    padding-top: 14px;
    @include d-grid(1fr, auto, 14px, flex-start, flex-start);
  }
  .breakdown-rows {
    padding-right: 12px;
    padding-bottom: 14px;
    padding-left: 12px;
    border-bottom: 1px solid $white-9;
    @include d-grid(3fr 1fr 1fr, auto, 12px, flex-start, flex-start);
    & > div:last-of-type {
      justify-self: flex-end;
    }
    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }
  }
}

.light-blue-info-strip {
  padding: 12px;
  background-color: rgba($purple-1, 0.06);
  border-radius: 4px;
  text-align: center;
}

.bulk-action-error-modal {
  text-align: center;

  .header-svg-icon {
    @include content-size(48, 48);
    margin-bottom: 8px;
  }
}

.upload-media-file-modal {
  .dialog-content {
    display: flex;
    flex-direction: column;
    min-height: 259px;
  }
  .file-csv-upload {
    .pos-rel {
      height: 80px;
    }
  }
  .uploaded-files-wrapper {
    width: 100%;
    .pos-rel {
      height: 80px;
    }
    .csv-replace {
      margin: 12px 0;

      .file-data-wrapper {
        margin-bottom: 0;

        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
